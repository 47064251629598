import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { Home } from '../pages/Home'

export const AppRouter = () => {
    const location = useLocation()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return (
        <Box
            sx={{
                backgroundColor: "#1D1F26",
                minHeight: "100vh",
                color: '#000'
            }}
        >
            <Routes>
                <Route path={`/`} element={<Home></Home>} />

                <Route path={`/*`} element={<Navigate to={"/"}></Navigate>} />
            </Routes >
        </Box>
    )
}
