import { Box, ThemeProvider, Typography } from '@mui/material'
import React from 'react'
import { FadeGallery } from '../components/FadeGallery'
import { appTheme } from '../styles/MUI/Theme'
import { ContactForm } from '../components/ContactUs'
import { RRSSContainer } from '../components/RRSS'
import { SelfPhoto } from '../components/SelfPhoto'
import logo from '../assets/logo sin fondo.png'

export const Home = () => {
  return (
    <ThemeProvider theme={appTheme}>
      <Box
      // pb={4}
      >
        <FadeGallery />
        <ContactForm />
        {/* <RRSSContainer /> */}
        <SelfPhoto />
        <Box
          sx={{
            width: "100%",
            height: 200,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection:'column',
            color:"#fff",
            gap:4
          }}
        >
          <Box height={80} >
            <img src={logo} alt="Logo" height={"100%"} />
          </Box>
          <Box px={2}>
            <Typography variant="h2" component="h3" sx={{ fontSize: "0.875rem", textAlign: "center" }} gutterBottom >Powered By <a style={{ color: "inherit" }} href="https://tesseractsoftwares.com/en-us">Tesseract</a> ®</Typography>            </Box>

        </Box>
      </Box>
    </ThemeProvider>
  )
}
