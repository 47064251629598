import React, { ChangeEvent, useState, FormEvent } from 'react'
import { Box, Paper, Grid, TextField, Button, Typography } from '@mui/material'
import axios from 'axios'
import Swal from 'sweetalert2'

export const ContactForm = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        name: '',
        email: '',
        summary: '',
        message: ''
    })
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setData({ ...data, [name]: value });
    };
    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setLoading(true)
        axios.post(`${process.env.REACT_APP_NEXUS_BACK_URL as string}/api/v1/Email`,
            {
                apiKey: process.env.REACT_APP_API_KEY,
                email: data.email,
                subject: data.summary,
                body: data.message,
                name: data.name
            }
        )
            .then((result) => {
                setLoading(false)
                Swal.fire({
                    title: "Correo enviado con exito",
                    icon: "success"
                })
                setData({
                    name: '',
                    email: '',
                    summary: '',
                    message: ''
                })

            }).catch((err) => {
                setLoading(false)
                Swal.fire({
                    title: "Algo salio mal, intentelo nuevamente o revise la informacion ingresada",
                    icon: "error"
                })
            });
    }
    return (
        <Box
            id={"contactanos"}
            my={4}
            sx={{ px: { xs: 2, sm: 3, md: 5, lg: 10, xl: 20 } }}
        >
            {/* <Paper
                elevation={6}
                sx={{
                    minHeight: 500,
                    p: 2,
                    backgroundColor:"rgba(255,255,255,0.7)"
                }}
            > */}
            <Typography variant='h1' fontSize={{ xs: 40, md: 60 }} fontWeight={"500"} textAlign={'center'} my={2} color={"#fff"}>
                Contáctanos
            </Typography>
            <Box onSubmit={handleSubmit} component={"form"} sx={{ "fieldset, css-ij471v-MuiInputBase-root-MuiOutlinedInput-root:hover": { borderColor: "#513c9f !important" }, "label": { color: "#fff !important" }, "input, textarea": { color: "#fff !important" }, display: "flex", flexDirection: "column", gap: 4, width: "100%", alignItems: "center" }}>
                <TextField color="secondary" required label="Nombre" sx={{ width: { xs: "80%", md: "50%" } }} name='name' onChange={handleChange} />
                <TextField color="secondary" required label="Email" sx={{ width: { xs: "80%", md: "50%" } }} name='email' onChange={handleChange} />
                <TextField color="secondary" required label="Motivo" sx={{ width: { xs: "80%", md: "50%" } }} name='summary' onChange={handleChange} />
                <TextField color="secondary" required label="Mensaje" multiline rows={5} sx={{ width: { xs: "80%", md: "50%" } }} name='message' onChange={handleChange} />
                <Box
                    sx={{
                        display: "flex",
                        gap: 4
                    }}
                >
                    <Button variant='contained' sx={{ p: 2 }} type='submit' disabled={loading} color='secondary'>
                        Enviar correo
                    </Button>
                </Box>
            </Box>
            {/* </Paper> */}
        </Box>
    )
}
