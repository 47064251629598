import React from 'react'
import { Box, Typography } from '@mui/material'
import { AiFillInstagram, AiOutlineTwitter, AiOutlineWhatsApp } from "react-icons/ai";
import { RRSSItems } from './RRSSItems';
export const RRSSContainer = () => {
    const rrss = [
        {
            icon: AiFillInstagram,
            url: "https://www.instagram.com/idiaquezeventos/"
        },
        {
            icon: AiOutlineWhatsApp,
            url: "https://api.whatsapp.com/send/?phone=56977743873&text&type=phone_number&app_absent=0"
        }
    ]
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 2,
                mt: 4
            }}
        >
            {/* <Typography variant='h5' textAlign={'center'}>
                Tambien contactanos por nuestras redes sociales
            </Typography> */}
            <Box
                sx={{
                    display: 'flex',
                    gap: 4
                }}
            >
                {
                    rrss.map((item, zIndex) =>
                        <RRSSItems key={zIndex} url={item.url} Icon={item.icon} />
                    )
                }
            </Box>
        </Box>
    )
}
