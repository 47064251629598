// import { blue, pink } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const appTheme = createTheme({
    palette: {
        primary: {
            main: '#513c9f',
            contrastText: "#fff",
            light: "#7d6db7",
            dark: "rgba( 81, 60, 159 ,0.7)"
        },
        secondary: {
            main: ' #271790',
            contrastText: "#f2f2f2",
            light: "#5d51ac",
            dark: "rgba(39, 23, 144 ,0.7)"
        },
    },
});