import React from 'react'
import { Box } from '@mui/material'
import { IconType } from 'react-icons/lib'

interface Props {
    url: string,
    Icon: IconType
}
export const RRSSItems = ({ url, Icon }: Props) => {
    return (
        <Box
            sx={{
                height: { xs: 30, md: 40, lg: 50 },
                width: { xs: 30, md: 40, lg: 50 },
                borderRadius: "50%",
                p: 0.5,
                backgroundColor: "#e6e6e6",
                cursor: 'pointer',
            }}
            onClick={() => window.open(url, '_blank')}
        >
            <Icon style={{ height: "100%", width: "100%" }} color='#513c9f' />
        </Box>
    )
}
