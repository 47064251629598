import React from 'react'
import { Box, Typography } from '@mui/material'
import image from '../../assets/SelfImage.png'

export const SelfPhoto = () => {
    return (
        <Box
            sx={{
                position: 'relative',
                height: "100vh",
                width: "100%",
            }}
        >
            <Box
                sx={{
                    backgroundImage: {
                        xs: "linear-gradient(to right, #513c9f, #513c9f, #513c9f, rgba(   81, 60, 159   ,0.9), rgba(   81, 60, 159   ,0.8), rgba(  96, 75, 168  ,0.7), rgba(  110, 91, 176 ,0.6), rgba(  124, 106, 185  ,0.5), rgba( 155, 142, 203 ,0.4), rgba( 188, 179, 221 ,0.3), rgba(221, 216, 239,0.2), rgba(221, 216, 239,0.1))",
                        md: "linear-gradient(to right, #513c9f, #513c9f, #513c9f, #513c9f, #513c9f, #604ba8, rgba(  110, 91, 176 ,1), rgba(  124, 106, 185  ,0.8), rgba( 155, 142, 203 ,0.6), rgba( 188, 179, 221 ,0.4), rgba(221, 216, 239,0.2), rgba(221, 216, 239,0.2))"
                    },
                    height: "100%",
                    width: "100%",
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    zIndex: 1

                }}
            >

            </Box>
            <Box
                sx={{
                    backgroundImage: `url(${image})`,
                    backgroundPosition: 'center center',
                    backgroundSize: "cover",
                    height: "100%",
                    width: { xs: "100%", md: "50%" },
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 0

                }}
            >

            </Box>
            <Box
                sx={{
                    height: "100%",
                    width: { xs: "100%", md: "50%" },
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: { xs: "center", md: "start" }

                }}
            >
                <Box
                    sx={{
                        maxWidth: 600,
                        p: 2
                    }}
                >
                    <Typography variant='h2' fontWeight={500} color={"#fff"}  >DJ Tomás Idiaquez</Typography>
                    {/* <Typography variant='body1' color={"#fff"} fontSize={16} whiteSpace={"pre-wrap"}>Dj internacional, 25 años, más de nueve años de experiencia.
                    </Typography> */}
                    <br />
                    <Typography variant='body1' color={"#fff"} fontSize={16} whiteSpace={"pre-wrap"}>
                        Tomás Idiaquez es un dj con más de nueve años de experiencia en discotecas y eventos privados, tanto en Chile como en España. Se destaca la aplicación de buena técnica, correcta lectura y conexión con el público, selección musical, equipos profesionales y buena relación con colegas y clientes.
                    </Typography>

                </Box>
            </Box>
        </Box>

    )
}
