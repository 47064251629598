import React, { useState, useEffect } from 'react'
import { useTransition, animated } from 'react-spring'
import image1 from '../../assets/image1.jpg'
import image2 from '../../assets/image2.jpg'
import image3 from '../../assets/image3.jpg'
import image4 from '../../assets/image4.jpg'
import image5 from '../../assets/image5.png'


import logo from '../../assets/logo sin fondo.png'
import './FadeEffect.css'
import { Box, Button, Typography } from '@mui/material'
import { RRSSContainer } from '../RRSS'


const images = [image1, image2, image3, image4, image5]
export const FadeGallery = () => {
  const [backgroundImage, setBackgroundImage] = useState(image1);
  useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = images.indexOf(backgroundImage);
      const nextIndex = (currentIndex + 1) % images.length;
      setBackgroundImage(images[nextIndex]);
    }, 3000); // Cambiar imagen cada 3 segundos

    return () => clearInterval(interval);
  }, [backgroundImage]);
  const transitions = useTransition(backgroundImage, {
    key: backgroundImage,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });
  return (
    <Box
      sx={{
        height: "100vh",
        position: 'relative',
        ".fade-background": {
          height: '100%'
        }
      }}
    >
      <Box
        sx={{
          backgroundColor: "rgba(81, 60, 159,0.7)",
          position: 'absolute',
          height: '100%',
          width: '100%',
          zIndex: 1
        }}
      >
      </Box>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          // px: 1,
          zIndex: 1,
          bottom: 0
        }}
      >
        <Typography variant='h2' fontSize={{ xs: 13, md: 20 }} px={2} pb={2} fontWeight={600} color={"#fff"}
          sx={{
            // textShadow: "3px 3px 0px #9f833c"
          }}
        >
          {"Idiaquez Producciones".toUpperCase()}
        </Typography>
      </Box>
      {transitions((style, item) => (
        <animated.div
          className="fade-background"
          style={{
            ...style,
            backgroundImage: `url(${item})`,
            zIndex: 0
          }}
        />
      ))}

      <Box
        sx={{
          position: 'absolute',
          zIndex: 100,
          transform: "translate(0,-50%)",
          width: "100%",
          // backgroundColor: 'rgba(0,0,0,0.5)',
          left: "0",
          top: '50%',
          py: 4,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            // backgroundColor: "rgba(81, 60, 159,0.4)",
            // height: 200,
            maxWidth: 500,
            color: "#fff",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            transform: "matrix(0.9962,-0.0872,0.0872,0.9962,-5,33)",
          }}
        >
          <Box
            sx={{
              // backgroundColor: "rgba(81, 60, 159,0.4)",
              // position: 'absolute',
              height: { xs: 100, md: 150 },
              // width: '100%',
              zIndex: 100
            }}
          >
            <img src={logo} alt="Idiaquez Producciones" height={"100%"} />
          </Box>

          {/* <Typography variant='h5' textAlign={'justify'}>
            {"Dj internacional".toUpperCase()}
          </Typography> */}
          <RRSSContainer></RRSSContainer>
        </Box>
      </Box>
    </Box>
  )
}
